import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './components/Home';
import ProfilePage from './components/Profile';
import Login from './components/SignIn';
import PolicyPage from './components/Policy';
import Footer from './components/Footer';
import RestaurantPage from './components/Restaurant';
import './App.css';


const App = () => {
  return (
      <Router>
        <div className='background'>
          <div className='canvas'>
            <div className='spacer'></div>
            <div className='spacer'></div>
            <Header />
            <div className='content'>
              <div className='main'>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/policy" element={<PolicyPage />} />
                  <Route path="/questions" element={<Login />} />
                  <Route path="/restaurants/:venueId" element={<RestaurantPage />} />
                </Routes>
              </div>
            </div>
            <Footer />
            <div className='spacer'></div>
            <div className='spacer'></div>
          </div>
        </div>
      </Router>
  );
};

export default App;
