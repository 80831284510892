import { useState } from 'react'
import { supabase } from '../supabase'

export default function Login() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')

  const handleLogin = async (event) => {
    event.preventDefault()

    setLoading(true)
    const { error } = await supabase.auth.signInWithOtp({ email })

    if (error) {
      alert(error.error_description || error.message)
    } else {
      alert('check your email for the login link!')
    }
    setLoading(false)
  }

  return (
    <div className="sign-in">
      <div className='spacer'></div>
      <p className="description">login or signup by email</p>
      <form className="form-widget" onSubmit={handleLogin}>
        <div>
          <input
            className="inputField"
            type="email"
            placeholder="email"
            value={email}
            required={true}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='spacer'></div>
        <div className='button-holder'>
          <button className='button-85' disabled={loading}>
            {loading ? <div className="loader"></div> : 'login'}
          </button>
        </div>
      </form>
    </div>
  )
}
