import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { CheckCircle } from 'react-feather';
import { supabase } from '../supabase';
import ReservationButton from './Money';

function formatDate(date) {
    const daysOfWeek = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];
    const monthsOfYear = ["jan", "feb", "march", "april", "may", "june", "july", "aug", "sept", "oct", "nov", "dec"];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    // Adding ordinal suffix to day
    const ordinalSuffix = (day) => {
        const j = day % 10,
              k = day % 100;
        if (j === 1 && k !== 11) {
            return day + "st";
        }
        if (j === 2 && k !== 12) {
            return day + "nd";
        }
        if (j === 3 && k !== 13) {
            return day + "rd";
        }
        return day + "th";
    };

    return `${dayOfWeek} ${month} ${ordinalSuffix(dayOfMonth)} ${year}`;
}

function cleanDate(date) {
    const dayOfMonth = date.getDate();
    const month = date.getMonth()+1;
    const year = date.getFullYear();
    return `${year}-${month}-${dayOfMonth}`;

}

function formatTime(timeString) {
    // Create a date object from the time string
    const timeParts = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), parseInt(timeParts[2]));

    // Format hours for 12-hour format
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Pad the minutes with leading zero if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Return the formatted time string
    return `${hours}:${formattedMinutes}${ampm}`;
}

function calculateCountdown(minutes_to_release) {
    const now = new Date();
    const releaseTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, minutes_to_release);
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const currentTime = currentHours * 60 + currentMinutes;
    
    if (currentTime > minutes_to_release) {
        // If current time is past the release time, add one more day to the release time
        releaseTime.setDate(releaseTime.getDate() + 1);
    }

    const distance = releaseTime - now;
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const year = releaseTime.getFullYear();
    const month = releaseTime.getMonth() + 1; // JavaScript months are 0-indexed, so add 1 for common format
    const day = releaseTime.getDate();

    return {
        year: year,
        month: month,
        day: day,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        distance: distance
    };
}

async function fetchUserAlert(email, restaurant_name, notification_time) {
    const { data, error } = await supabase
        .from('Alerts')
        .select('*')
        .eq('email', email)           // Querying based on session email
        .eq('restaurant_name', restaurant_name)    // Querying based on restaurant name
        .eq('notification_time', notification_time);                                 // Assuming there's only one match
    if (error) {
        return false;
    } else if (data) {
        if (data.length >= 1) {
            return true;
        }
        return false;
    } else {
        return false;
    }
}

const RestaurantPage = () => {
    const { venueId } = useParams();
    const { userData, session } = useAuth();
    const navigate = useNavigate();
    const [restaurantDetails, setRestaurantDetails] = useState(null);
    const [countdown, setCountdown] = useState({
        year: ' ',
        month: ' ',
        day: ' ',
        hours: ' ',
        minutes: ' ',
        seconds: ' ',
    });
    const [countdownFlag, setCountdownFlag] = useState(false)
    const [userAlert, setUserAlert] = useState(false);
    const [notificationTime, setNotificationTime] = useState(null);
    const [tableList, setTableList] = useState(null);
    const [reservationDate, setReservationDate] = useState(null);
    const [smReservationDate, setSmReservationDate] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [visisbleDate, setVisibleDate] = useState(true);

    // Get Restaurant Details
    useEffect(() => {
        async function fetchRestaurantDetails() {
            try {
                const response = await fetch(`https://resygas-api.com/get_restaurant?venue_id=${venueId}`);
                const data = await response.json();
                if (data) {
                    const now = new Date();
                    const rd = new Date(now.getFullYear(), now.getMonth(), now.getDate() + data.days_to_release, 0, 0);
                    const minutes_to_release = data.minutes_to_release
                    
                    const hours = now.getHours()
                    const mins = now.getMinutes()
                    const total_mins = hours * 60 + mins

                    if (total_mins > minutes_to_release) {
                        rd.setDate(rd.getDate() + 1)
                    }
                    setRestaurantDetails(data);
                    setReservationDate(formatDate(rd));
                    setSmReservationDate(cleanDate(rd));
                }
                
            } catch (error) {
                console.error('Failed to fetch restaurant details:', error);
            }
        }
        fetchRestaurantDetails();
    }, [venueId]);

    // Get Countdown Details
    useEffect(() => {
        if (restaurantDetails) {
            const timer = setInterval(() => {
                const result = calculateCountdown(restaurantDetails.minutes_to_release);
                setCountdown(result);
                if (!countdownFlag && result) {
                    setCountdownFlag(true);
                }
            }, 1000); // set interval to run every 1000 milliseconds (every second)
            return () => clearInterval(timer);
        }
    }, [restaurantDetails, venueId, countdownFlag]);
    
    // Get User Alerts
    useEffect(() => {
        async function fetchAlerts() {
            const now = new Date();
            const currentHours = now.getHours();
            const currentMinutes = now.getMinutes();
            const currentTime = currentHours * 60 + currentMinutes;
            if (currentTime > restaurantDetails.minutes_to_release) {
                // If current time is past the release time, add one more day to the release time
                const nT = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0,restaurantDetails.minutes_to_release + 225);
                const formattedTime = nT.getFullYear() + '-' +
                    String(nT.getMonth() + 1).padStart(2, '0') + '-' + 
                    String(nT.getDate()).padStart(2, '0') + ' ' +
                    String(nT.getHours()).padStart(2, '0') + ':' +
                    String(nT.getMinutes()).padStart(2, '0') + ':' +
                    String(nT.getSeconds()).padStart(2, '0');
                setNotificationTime(formattedTime);
            } else {
                const nT = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,restaurantDetails.minutes_to_release + 225);
                const formattedTime = nT.getFullYear() + '-' +
                    String(nT.getMonth() + 1).padStart(2, '0') + '-' + 
                    String(nT.getDate()).padStart(2, '0') + ' ' +
                    String(nT.getHours()).padStart(2, '0') + ':' +
                    String(nT.getMinutes()).padStart(2, '0') + ':' +
                    String(nT.getSeconds()).padStart(2, '0');
                setNotificationTime(formattedTime);
            }
            try {
                const result = await fetchUserAlert(session.user.email, restaurantDetails.name, notificationTime);
                if (result) {
                    setUserAlert(true);
                }
            } catch (error) {
                console.error('Error fetching alert:', error);
            }
        }
        async function fetchTables() {
            try {
                const response = await fetch(`https://resygas-api.com/tables/${venueId}`);
                const data = await response.json();
                if (data) {
                    setTableList(data);
                }
            } catch (error) {
                throw error;
            }
        }
        if (countdownFlag && restaurantDetails && session) {
            fetchAlerts();
            fetchTables();
        }
    }, [venueId, restaurantDetails, countdownFlag, notificationTime, session]);

    async function callSendNotificationApi(type, value, restaurantName, reservationTime, email) {
        try {
            const response = await fetch(`https://resygas-api.com/send-notification?type=${type}&value=${value}&restaurant_name=${restaurantName}&reservation_time=${reservationTime}&venue_id=${venueId}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json'
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log('Notification sent successfully:', data);
    
            // Insert data into Supabase 'Alerts' table
            const { data: supabaseData, error } = await supabase
                .from('Alerts')
                .insert([
                    {
                        type: type,
                        email: email, // Using session email
                        restaurant_name: restaurantName, // Using the restaurant name passed to the function
                        notification_time: reservationTime, // Using the reservation time passed to the function
                    }
                ]);
    
            if (error) {
                throw new Error('Error inserting alert: ' + error.message);
            }
            navigate(0);
        } catch (error) {
            console.error('Error:', error.message);
        } finally {
            navigate(0)
        }
    }

    function confirmReservation(selectedTable) {
        setVisibleDate(false);
        // Implement API call or other actions here
    }

    return (
        <div className='inner-profile'>
            {restaurantDetails ? (
                <div className='innerprofile'>
                    <div className='spacer'></div>
                    <div className='innerprofile-header'>
                        <h2>{restaurantDetails.name.toLowerCase()}</h2>
                    </div>
                    {visisbleDate && (
                        <div className='innerprofile-header'>
                            <div className='spacer'></div>
                            <h3>reservations for </h3>
                            <h2>{reservationDate}</h2>
                        </div>
                    )}
                    <div className='spacer'></div>
                    <div className='innerprofile-header'>
                        <h3>reservations open in</h3>
                    </div>
                    <div className='countdown'>
                        <div className='time-obj'>
                            <h2 className='countdown-time'>{countdown.hours}</h2>
                            <h3 className='countdown-label'>hrs</h3>
                        </div>
                        <div className='time-obj'>
                            <h2 className='countdown-time'>{countdown.minutes}</h2>
                            <h3 className='countdown-label'>mins</h3>
                        </div>
                        <div className='time-obj'>
                            <h2 className='countdown-time'>{countdown.seconds}</h2>
                            <h3 className='countdown-label'>secs</h3>
                        </div>
                    </div>
                    <div className='spacer'></div>
                    
                    <div className='table-selection'>
                        {session ? (
                            tableList ? (
                                tableList.length > 0 ? (
                                    userData ? (
                                        countdown.distance < 1800000 ? ( // 900000
                                            visisbleDate ? (
                                                <div className='confirmations'>
                                                    <div className='tselect'>
                                                        <select onChange={e => setSelectedTable(tableList[e.target.value])}>
                                                            {tableList.map((table, index) => (
                                                                <option key={index} value={index}>
                                                                    {`${table.table_type.toLowerCase()} @ ${formatTime(table.time_string)} for ${table.party_size}`}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div className='spacer'></div>
                                                    <div className='button-holder'>
                                                        <button className='button-85' onClick={() => confirmReservation(selectedTable)}>confirm</button>
                                                    </div>
                                                    
                                                </div>
                                            ) : ( 
                                                <ReservationButton
                                                    email={userData.resy_email}
                                                    password={userData.password}
                                                    venue_id={venueId}
                                                    reservation_date={smReservationDate}
                                                    reservation_time={selectedTable.time_string}
                                                    template_id={selectedTable.template_id}
                                                    service_id={selectedTable.service_id}
                                                    party_size={selectedTable.party_size}
                                                    table_type={selectedTable.table_type}
                                                />
                                            )
                                            // Display confirm button if time difference is 5 minutes or less
                                            
                                        ) : userData.phone ? (
                                            userAlert ? (
                                                <div className='profile-block'>
                                                    <h3>text alert enabled</h3>
                                                    <div className="verified-status">
                                                        <CheckCircle color="green" size={14} /> {/* Adjust the size to fit the text */}
                                                    </div>
                                                </div>
                                            ) : (
                                                // Display text alert button if time difference is more than 5 minutes and phone is available
                                                <div className='button-holder'>
                                                    <button className='button-85' onClick={() => callSendNotificationApi('phone', userData.phone, restaurantDetails.name, notificationTime, session.user.email)}>
                                                        {isLoading ? <div className="loader"></div> : 'get text alert'}
                                                    </button>
                                                </div>
                                            )
                                            
                                        ) : (
                                            userAlert ? (
                                                <div className='profile-block'>
                                                    <h3>email alert enabled</h3>
                                                    <div className="verified-status">
                                                        <CheckCircle color="green" size={14} /> {/* Adjust the size to fit the text */}
                                                    </div>
                                                </div>
                                            ) : (
                                                // Display email alert button if time difference is more than 5 minutes and phone is available
                                                <div className='button-holder'>
                                                    <button className='button-85' onClick={() => callSendNotificationApi('phone', userData.phone, restaurantDetails.name, notificationTime, session.user.email)}>
                                                        {isLoading ? <div className="loader"></div> : 'get email alert'}
                                                    </button>
                                                </div>
                                            )
                                        )
                                    ) : (
                                        // Prompt to connect Resy if userData is not available
                                        <div className='button-holder'>
                                            <button className='button-85' onClick={() => navigate('/profile')}>connect resy</button>
                                        </div>
                                    )
                                ) : (
                                    // Display login button if no session
                                    <h3>tables coming soon...</h3>
                                )
    
                            ) : (
                                // Display login button if no session
                                <h3>tables coming soon...</h3>
                            )
                        ) : (
                            <div className='button-holder'>
                                <button className='button-85' onClick={() => navigate('/login')}>login</button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className='spacer'>
                
                </div>
            )}
        </div>
    );
}

export default RestaurantPage;