const PolicyPage = () => {
    

    return (
        <div className='home'>
            <div className='spacer'></div>
            <p>
                privacy policy for resygas
                <br />
                last updated 2024-04-26
                <br /><br />
                At resygas, accessible from resygas.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by resygas and how we use it.
                <br /><br />
                1. General Information
                <br />
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in resygas. This policy is not applicable to any information collected offline or via channels other than this website.
                <br /><br />
                2. Consent
                <br />
                By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                <br /><br />
                3. Information we collect
                <br />
                The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal data.
                If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.<br /><br />
                When you register for Text Alerts, we may ask for your contact information, including items such as name and phone number.
                <br /><br />
                4. How we use your information
                <br />
                We use the information we collect in various ways, including to:
                <br />
                Provide, operate, and maintain our website.
                Improve, personalize, and expand our website.
                Understand and analyze how you use our website.
                Develop new products, services, features, and functionality.
                Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.
                Send you text alerts.
            </p>
        </div>
    );
};

export default PolicyPage;