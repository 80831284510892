import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { User } from 'react-feather';  // Importing the User icon from react-feather
import { useAuth } from './AuthContext';  // Assuming useAuth is exported correctly from AuthContext

const Header = () => {
  const navigate = useNavigate();
  const { session } = useAuth();  // Using session from AuthContext

  const handleProfileClick = () => {
    navigate('/profile');  // Redirects to the Profile page
  };

  const handleLogoClick = () => {
    navigate('/');  // Redirects to the Home page (root)
  };

  return (
    <div className="header">
      <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <h2 style={{ margin: 0 }}>resygas</h2>
      </div>
      <div>
        {session ? (
          <User style={{ cursor: 'pointer', color: 'white', width: '35px', height: '35px'}} onClick={handleProfileClick}/>
        ) : (
          <Link to="/login" style={{ color: 'white', textDecoration: 'none', fontSize: '24px'}}>login</Link>
        )}
      </div>
    </div>
  );
};

export default Header;
