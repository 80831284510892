import React from "react";

function Footer() {
  return (
    <div className="footer">
      <p>© 2024 by resygas</p>
    </div>
  );
}

export default Footer;
