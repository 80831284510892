import React, { createContext, useState, useEffect, useContext } from 'react'; 
import { supabase } from '../supabase';

// Create context
const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
    const [session, setSession] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        // Check active session and update state
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
            if (session && !userData) {
                fetchUserData(session.user.email);
            } 
        });

        // Handle session state changes
        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            if (session && !userData) {
                fetchUserData(session.user.email);
            } else if (!session) {
                setUserData(null); // Clear user data when session ends
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    // Fetch user data from Supabase
    const fetchUserData = async (email) => {
        let { data, error } = await supabase
            .from('Users')
            .select('resy_email, resy_password, phone')
            .eq('email', email);
        if (data.length === 0) {
            console.log('user is not resy verified');
            return;
        } if (data.length > 0) {
            const fmtdata = {
                'resy_email': data[0].resy_email,
                'password': data[0].resy_password,
                'phone': data[0].phone
            };
            setUserData(fmtdata)
            return
        } else {
            console.error('query to supabase failed');
            console.log(error);
            return;
        }
    };

    // Value provided to context consumers
    const value = {
        session,
        userData, // now userData is available to be used in other components
        supabase
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

