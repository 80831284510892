import React, { useState } from 'react';
import { supabase } from '../supabase'; // Adjust the path to where your Supabase client is initialized
import { useAuth } from './AuthContext'; // Adjust the path to your auth context
import { CheckCircle } from 'react-feather'; // Import specific icons from react-feather
import { useNavigate } from 'react-router-dom';


const Profile = () => {
    const { userData, session } = useAuth();
    const [resyEmail, setResyEmail] = useState('');
    const [resyPassword, setResyPassword] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleResyVerification = async (email, password) => {
        setIsLoading(true);
        // Make sure to replace 'https://your-endpoint.com' with the correct endpoint URL
        try {
            const emailfmt = encodeURIComponent(email);
            const passwordfmt = encodeURIComponent(password);
            const response = await fetch(`https://resygas-api.com/auth?email=${emailfmt}&password=${passwordfmt}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {

                // Update the user profile in the Supabase 'users' table
                const { error } = await supabase
                    .from('Users')
                    .insert({ resy_email: email, resy_password: password, phone: result.phone, email: session.user.email })
                    .eq('email', session.user.email);

                if (error) {
                    console.error('Error updating user with Resy credentials:', error);
                } else {
                    navigate(0);
                }
            }
        } catch (error) {
            console.error('Error verifying Resy credentials:', error);
        }
        setIsLoading(false);
    };

    const handleLogout = async () => {
        const { error } = await supabase.auth.signOut();
        if (error) {
            console.error('Error during logout:', error);
        } else {
            navigate('/'); // Redirect to the login page or any other route after logout
        }
    };

    return (
        <div className='profile-section'>
            {session ? (
                <div className='profile-section'>
                    
                    <div className='spacer'></div>
                    <h2>resygas profile</h2>
                    <div className='hsspacer'></div>
                    {session && (
                        <div className='profile-block'>
                            <h3>{session.user.email}</h3>
                            {session.user.email && (
                                <div className="verified-status">
                                    <CheckCircle color="green" size={14} /> {/* Adjust the size to fit the text */}
                                </div>
                            )}
                        </div>
                    )}
                    <div className='spacer'></div>
                    <h2>resy profile</h2>
                    
                    {userData && (
                        <div className='blocks'>
                            <div className='hsspacer'></div>
                            <div className='profile-block'>
                                <h3>{userData.resy_email}</h3>
                                <div className="verified-status">
                                    <CheckCircle color="green" size={14} /> {/* Adjust the size to fit the text */}
                                </div>
                            </div>
                        </div>
                    )}
                    
                    {userData && (
                        <div className='blocks'>
                            <div className='hsspacer'></div>
                            <div className='profile-block'>
                                <h3>{userData.phone}</h3>
                                <div className="verified-status">
                                    <CheckCircle color="green" size={14} /> {/* Adjust the size to fit the text */}
                                </div>
                            </div>
                        </div>
                    )}
                    {!userData && (
                        <div className='blocks'>
                            <div className='hsspacer'></div>
                            <div className='profile-block'>
                                <input
                                    type="email"
                                    value={resyEmail}
                                    onChange={e => setResyEmail(e.target.value)}
                                    placeholder="resy email"
                                />
                                
                            </div>
                            <div className='hsspacer'></div>
                            <div className='profile-block'>
                                <input
                                    type="password"
                                    value={resyPassword}
                                    onChange={e => setResyPassword(e.target.value)}
                                    placeholder="resy password"
                                />
                                
                            </div>
                            <div className='spacer'></div>
                            <div className='profile-block'>
                                <button className='button-85' onClick={() => handleResyVerification(resyEmail, resyPassword)}>
                                    {isLoading ? <div className="loader"></div> : 'verify'}
                                </button>
                            </div>
                        </div>
                    )}
                    <div className='spacer'></div>
                    <div className='spacer'></div>
                    <div className='profile-block'>
                        <button className='button-85' onClick={handleLogout}>
                            logout
                        </button>
                    </div>
                </div>
            ) : (
                <div className='button-holder'>
                    <button className='button-85' onClick={() => navigate('/login')}>login</button>
                </div>
            )}
        </div>
    );
};

export default Profile;
