import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';

function formatDate(date) {
    const daysOfWeek = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];
    const monthsOfYear = ["jan", "feb", "march", "april", "may", "june", "july", "aug", "sept", "oct", "nov", "dec"];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = monthsOfYear[date.getMonth()];
    const year = date.getFullYear();

    // Adding ordinal suffix to day
    const ordinalSuffix = (day) => {
        const j = day % 10,
              k = day % 100;
        if (j === 1 && k !== 11) {
            return day + "st";
        }
        if (j === 2 && k !== 12) {
            return day + "nd";
        }
        if (j === 3 && k !== 13) {
            return day + "rd";
        }
        return day + "th";
    };

    return `${dayOfWeek} ${month} ${ordinalSuffix(dayOfMonth)} ${year}`;
}

function formatTime(timeString) {
    // Create a date object from the time string
    const timeParts = timeString.split(':');
    const date = new Date();
    date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), parseInt(timeParts[2]));

    // Format hours for 12-hour format
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Pad the minutes with leading zero if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    // Return the formatted time string
    return `${hours}:${formattedMinutes}${ampm}`;
}

function ReservationButton({
    venue_id, reservation_date, reservation_time,
    template_id, service_id, party_size, table_type
}) {
    const [isButtonClickable, setIsButtonClickable] = useState(false);
    const { userData } = useAuth();
    const [tokens, setTokens] = useState([]);
    const [authDetails, setAuthDetails] = useState(null);
    const [progress, setProgress] = useState(0);
    const [tokensLoaded, setTokensLoaded] = useState(false);
    const [tokensLoading, setTokensLoading] = useState(false);

    // Authentication using the auth endpoint
    async function authenticateUser() {
        try {
            const emailfmt = encodeURIComponent(userData.resy_email);
            const passwordfmt = encodeURIComponent(userData.password);
            const response = await fetch(`https://resygas-api.com/auth?email=${emailfmt}&password=${passwordfmt}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json'
                },
            });
            const result = await response.json();
            setAuthDetails(result);
        } catch (error) {
            console.error('Error during authentication:', error);
        }
    }

    async function generateToken() {
        try {
            const venueIdFmt = encodeURIComponent(venue_id);
            const templateIdFmt = encodeURIComponent(template_id);
            const serviceIdFmt = encodeURIComponent(service_id);
            const reservationDateFmt = encodeURIComponent(reservation_date);
            const reservationTimeFmt = encodeURIComponent(reservation_time);
            const tableTypeFmt = encodeURIComponent(table_type);
            const partySizeFmt = encodeURIComponent(party_size);
            
            const response = await fetch(`https://resygas-api.com/fetch_reservation_token?venue_id=${venueIdFmt}&template_id=${templateIdFmt}&service_id=${serviceIdFmt}&reservation_date=${reservationDateFmt}&reservation_time=${reservationTimeFmt}&party_size=${partySizeFmt}&table_type=${tableTypeFmt}`, {
                method: 'GET',
                headers: {
                    'accept': 'application/json'
                },
            });
            const result = await response.json();
            return result.tokens; // Return an array of tokens
        } catch (error) {
            console.error('Error generating token:', error);
            return [];
        }
    }

    async function makeReservation(reservation_tokens) {
        try {
            const response = await fetch('https://resygas-api.com/make_reservation', {
                method: 'POST',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    auth_token: authDetails.auth_token,
                    payment_method_id: authDetails.payment_method_id,
                    reservation_tokens: reservation_tokens
                })
            });
            const result = await response.json();
            return result;
        } catch (error) {
            console.error('Error making reservation:', error);
            return null;
        }
    }

    async function initializeTokens() {
        try {
            setTokensLoading(true);
            let aggregatedTokens = [];
            const totalRequests = 10;
            const progressIncrement = 10; // 10% per request

            for (let i = 0; i < totalRequests; i++) {
                const tokens = await generateToken();
                aggregatedTokens = [...aggregatedTokens, ...tokens];
                setTokens(aggregatedTokens);

                // Update progress
                setProgress((prevProgress) => prevProgress + progressIncrement);
            }

            setTokensLoaded(true);
            setTokensLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            if (!authDetails) {
                await authenticateUser();
            }
            if (authDetails && !tokensLoaded && !tokensLoading) {
                await initializeTokens();
            }
        }
        
        fetchData();
    }, [venue_id, reservation_date, reservation_time, template_id, service_id, party_size, table_type, authDetails]);

    async function handleReservationClick() {
        const tokensToUse = tokens.splice(0, 100).join(',');
        await makeReservation(tokensToUse);
        setTokens([...tokens]);
        setProgress(prev => Math.max(0, prev - 10)); // Update progress as needed
    }

    return (
        <div className='innerprofile'>
            <div className='innerprofile-header'>
                <h2>gas meter</h2>
                <div className='smspacer'></div>
                <div className='outer-pb' style={{ width: '200px', backgroundColor: 'white', height: '40px', borderRadius: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', boxSizing:'border-box', padding: '1px 4px' }}>
                    <div className="progress-bar" style={{ width: `${progress * 2}px`, height: '30px', borderRadius: '10px' }}>
                    </div>
                </div>
                <div style={{ height: '5px'}}></div>
                <p className='innerprofile-header'>{`${tokens.length} requests`}</p>
            </div>
            <div className='spacer'></div>
            <button className='button-65' disabled={tokens.length < 1} onClick={handleReservationClick}>
                <strong>reserve</strong>
                <p className='innerprofile-header' style={{ fontSize: '10px'}}>{`100 requests`}</p>
            </button>
        </div>
    );
}

export default ReservationButton;
