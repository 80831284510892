import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const [restaurants, setRestaurants] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRestaurants = async () => {
            try {
                const response = await fetch('https://resygas-api.com/restaurants');
                const data = await response.json();
                setRestaurants(data);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch restaurants:', error);
                setLoading(false);
            }
        };

        fetchRestaurants();
    }, []);

    if (loading) return <div>loading...</div>;

    return (
        <div className='home'>
            <div className='spacer'></div>
            <div className="restaurant-list">
                {restaurants.map(restaurant => (
                    <button
                        className='restaurant-button'
                        key={restaurant.venue_id}
                        onClick={() => navigate(`/restaurants/${restaurant.venue_id}`)}
                    >
                        {restaurant.name.toLowerCase()}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default HomePage;

